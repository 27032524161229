<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Legacy Attribute
            <template slot="actions">
                <mercur-button @click='openAddAttributeDialog' class="btn btn-raised btn-yellow text-uppercase" v-if="isAllowedTo('createLegacyAttribute')">
                    <i class="fas fa-plus"></i>
                    Add new legacy attribute
                </mercur-button>
            </template>
        </grid-header>
        <ag-grid-vue
            class="ag-grid fill ag-theme-material border"
            :columnDefs="columnDefs"
            :grid-options="gridOptions"
            :animateRows="true"
            rowModelType="serverSide"
            :masterDetail="true"
            :autoGroupColumnDef="autoGroupColumnDef"
            :pagination="true"
            :enableRangeSelection="false"
            :defaultColDef="defaultColDef"
            :suppressCellSelection="true"
            :detailCellRendererParams="detailCellRendererParams"
            @grid-ready="onGridReady"
            @model-updated="onModelUpdated"
            :getRowNodeId="getRowNodeId"
            :detailRowHeight="400"
            v-if="isAllowedTo('getLegacyAttributes')"
        ></ag-grid-vue>

        <mercur-dialog :is-open.sync="addLegacyAttributeDialogShow">
            <div slot="header">
                <h3 class="font-weight-normal">{{addLegacyAttributeDialogParams.isEdit ? 'Edit' : 'Add'}} attribute</h3>
            </div>
            <form @submit.prevent="submitAddAttribute">
                <div slot="default">
                    <p v-if="addLegacyAttributeDialogParams.isEdit">Please enter the new name for the attribute.</p>
                    <p v-else>Please enter the name for the new legacy attribute.</p>

                    <div>
                        <div v-if="$v.addLegacyAttributeDialogParams.legacyAttributeName.$model">
                            <h3 class="font-weight-normal">Legacy Attribute name</h3>
                        </div>
                        <v-select
                            :disabled="!legacyAttributeNames || addLegacyAttributeDialogLoading"
                            taggable
                            :options="legacyAttributeNames"
                            :selectOnTab="true"
                            placeholder="Legacy Attribute name"
                            v-model="$v.addLegacyAttributeDialogParams.legacyAttributeName.$model"
                        ></v-select>
                        <div v-if="legacyAttributeNames && $v.addLegacyAttributeDialogParams.legacyAttributeName.$model">
                           <em class="v-select-option-add-text warning" v-if="!legacyAttributeNames.includes($v.addLegacyAttributeDialogParams.legacyAttributeName.$model)">This attribute does not yet exist, make sure the spelling matches Presta!</em>
                        </div>
                        <div v-if="$v.addLegacyAttributeDialogParams.legacyAttributeName.$error">
                            <span class="error" v-if="!$v.addLegacyAttributeDialogParams.legacyAttributeName.required">This is a required field</span>
                        </div>
                    </div>

                    <div>
                        <h3 v-if="$v.addLegacyAttributeDialogParams.legacyAttributeOption.$model" class="font-weight-normal">Legacy Attribute option</h3>
                        <v-select
                            :disabled="!legacyAttributeOptions || addLegacyAttributeDialogLoading"
                            taggable
                            :options="legacyAttributeOptions"
                            :selectOnTab="true"
                            placeholder="Legacy Attribute option"
                            v-model="$v.addLegacyAttributeDialogParams.legacyAttributeOption.$model"
                        ></v-select>
                        <div v-if="legacyAttributeOptions && $v.addLegacyAttributeDialogParams.legacyAttributeOption.$model">
                            <em class="v-select-option-add-text warning" v-if="!legacyAttributeOptions.includes($v.addLegacyAttributeDialogParams.legacyAttributeOption.$model)">This option does not yet exist, make sure the spelling matches Presta!</em>
                        </div>
                        <div v-if="$v.addLegacyAttributeDialogParams.legacyAttributeOption.$error">
                            <span class="error" v-if="!$v.addLegacyAttributeDialogParams.legacyAttributeOption.required">This is a required field</span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h3 v-if="$v.addLegacyAttributeDialogParams.productFamily.$model" class="font-weight-normal">Legacy Attribute option</h3>
                        <v-select
                            :disabled="!legacyProducts || addLegacyAttributeDialogLoading"
                            v-model.trim="$v.addLegacyAttributeDialogParams.productFamily.$model"
                            :options="legacyProducts"
                            required
                            placeholder="Product family (S2P name)"
                        ></v-select>
                    </div>

                    <h3 class="font-weight-normal">
                        Mercur attribute <span class="error" v-if="$v.addLegacyAttributeDialogParams.attributes.$error">Please check the mapping below</span>
                    </h3>
                    <div class="mercur-attribute-sets">
                        <div class="mercur-attribute-set"
                             v-for="(v, key) in $v.addLegacyAttributeDialogParams.attributes.$model"
                             :key="v.iterationKey"
                        >
                            <mercur-attribute-selector
                                v-model="$v.addLegacyAttributeDialogParams.attributes.$model[key]"
                                :attributes="attributes"
                                :initial-attribute-name="v.attributeName"
                                :initial-attribute-option="v.attributeOption"
                                :legacy-attribute-id="v.legacyAttributeId"
                                @input="$v.addLegacyAttributeDialogParams.attributes.$touch"
                            ></mercur-attribute-selector>
                            <mercur-button class="btn btn-icon" v-if="$v.addLegacyAttributeDialogParams.attributes.$model.length > 1" @click="removeMercurAttribute(key)">
                                <i class="fas fa-trash"></i>
                            </mercur-button>
                        </div>
                    </div>
                    <mercur-button class="btn btn-icon btn-raised" @click="addMercurAttribute">
                        <i class="fas fa-plus"></i>
                    </mercur-button>
                </div>

                <div slot="footer" class="text-right">
                    <mercur-button class="btn btn-yellow text-uppercase" @click="addLegacyAttributeDialogShow = false">Close</mercur-button>
                    <mercur-button class="btn btn-yellow text-uppercase" :disabled="addLegacyAttributeDialogLoading || $v.addLegacyAttributeDialogParams.$invalid" type="submit">Save</mercur-button>
                </div>
            </form>
            <mercur-progress-bar indeterminate v-if="addLegacyAttributeDialogLoading || addLegacyAttributeDialogFetching"></mercur-progress-bar>
        </mercur-dialog>
    </mercur-card>
</template>

<script>

import CONFIG from '@root/config'

import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'

import { required, minLength } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import MercurAttributeSelector from '@/components/attributes/MercurAttributeSelector'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'LegacyAttributesOverview',
    components: { MercurAttributeSelector, AgGridVue, vSelect, GridHeader },

    data () {
        return {
            searchFields: ['legacyAttributeName'],
            quickfilter: '',
            url: CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS.replace('{supplierId}', this.supplierId),
            attributes: [],
            columnDefs: null,
            filters: {
                search: '',
            },
            filterModel: {},
            rowData: null,
            gridOptions: null,
            gridApi: null,
            autoGroupColumnDef: null,
            addLegacyAttributeDialogShow: false,
            addLegacyAttributeDialogParams: {
                attributes: [],
                legacyAttributeName: null,
                legacyAttributeOption: null,
                removedAttributes: [],
            },
            addLegacyAttributeDialogLoading: false,
            addLegacyAttributeDialogFetching: false,
            addAttributeOptionDialogShow: false,
            addAttributeOptionDialogParams: false,
            addAttributeOptionDialogLoading: false,
            legacyAttributeNames: null,
            legacyAttributeOptions: null,
            serverSideDatasource: null,
            detailCellRendererParams: null,
            defaultColDef: null,
            getRowNodeId: ({ attributeName }) => attributeName,
            legacyProducts: null,
        }
    },

    validations: {
        addLegacyAttributeDialogParams: {
            attributes: {
                required,
                $each: {
                    required,
                    minLength: minLength(1),
                },
            },
            legacyAttributeName: {
                required,
            },
            legacyAttributeOption: {
                required,
            },
            productFamily: {
                required,
            },
        },
    },
    beforeMount () {
        this.columnDefs = [
            {
                headerName: 'Legacy Attribute name',
                field: 'legacyAttributeName',
                cellRenderer: this.isAllowedTo('getLegacyAttributeOptionsByLegacyAttributeName') && 'agGroupCellRenderer',
                sortable: true,
            },
        ]
        this.detailCellRendererParams = (params) => {
            const legacyAttributeName = params.data.legacyAttributeName
            return {
                detailGridOptions: {
                    pagination: true,
                    rowModelType: 'serverSide',
                    columnDefs: [
                        {
                            field: 'legacyAttributeOption',
                        },
                        {
                            field: 'productFamily',
                        },
                        {
                            field: 'attributeName',
                        },
                        {
                            field: 'attributeOption',
                        },
                        {
                            headerName: '',
                            cellRendererFramework: 'AttributeTableActionCell',
                            cellRendererParams: {
                                openEditAttributeOption: (params) => {
                                    this.openEditAttributeDialog(params)
                                },
                                disabled: () => !this.isAllowedTo('getLegacyAttributeByNameOptionAndProductGroup'),
                                openAddAttributeOption: false,
                            },
                        },
                    ],
                    defaultColDef: {
                        resizable: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            applyButton: true,
                            clearButton: true,
                            newRowsAction: 'keep',
                        },
                    },
                    onFirstDataRendered (params) {
                        params.columnApi.autoSizeAllColumns()
                    },
                    onGridReady: (params) => {
                        params.api.setSortModel([{
                            colId: 'legacyAttributeOption',
                            sort: 'asc',
                        }])

                        if (!this.isAllowedTo('getLegacyAttributeOptionsByLegacyAttributeName')) {
                            return
                        }

                        params.api.setServerSideDatasource(new ApiServerSideDataSource({
                            $api: this.$api,
                            url: CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.LIST_OPTIONS.replace('{legacyAttributeName}', legacyAttributeName),
                            getFilterModel: () => this.filterModel,
                            responseHandler: (successCallback, { data }) => {
                                params.api.hideOverlay()

                                if (data.items.length === 0) {
                                    params.api.showNoRowsOverlay()
                                }

                                successCallback(data.items, data.items[0].totalRows)
                                params.api.sizeColumnsToFit()
                            },
                        }))

                        setTimeout(() => {
                            params.api.sizeColumnsToFit()
                        }, 100)
                        window.addEventListener('resize', () => {
                            params.api.sizeColumnsToFit()
                        })
                    },
                },
                getDetailRowData: params => { },
            }
        }
        this.gridOptions = {
            rowModelType: 'serverSide',
        }
        this.defaultColDef = {
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                applyButton: true,
                clearButton: true,
                newRowsAction: 'keep',
            },
        }
    },

    watch: {
        'filters.search' (value) {
            this.gridApi.setFilterModel({
                legacyAttributeName: {
                    type: 'contains',
                    filter: value,
                    filterType: 'text',
                },
            })
        },
        'addLegacyAttributeDialogParams.legacyAttributeName' (value) {
            this.legacyAttributeOptions = null
            if (!value) {
                return
            }

            if (!this.legacyAttributeNames.includes(value)) {
                this.legacyAttributeOptions = []

                return
            }

            if (!this.isAllowedTo('getLegacyAttributeOptionsByLegacyAttributeName')) {
                return
            }

            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.LIST_OPTIONS.replace('{legacyAttributeName}', value)
            this.addJob(url)
            this.addLegacyAttributeDialogFetching = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.legacyAttributeOptions = data.data.items.flatMap(({ legacyAttributeOption }) => legacyAttributeOption).filter((value, index, list) => list.indexOf(value) === index)
            }).finally(() => {
                this.finishJob(url)
                this.addLegacyAttributeDialogFetching = false
            })
        },
    },

    methods: {
        addMercurAttribute () {
            this.addLegacyAttributeDialogParams.attributes.push({
                iterationKey: new Date().getTime(),
            })
        },
        removeMercurAttribute (key) {
            const attribute = this.addLegacyAttributeDialogParams.attributes[key]
            if (attribute) {
                this.addLegacyAttributeDialogParams.removedAttributes.push(attribute.legacyAttributeId)
            }
            this.addLegacyAttributeDialogParams.attributes.splice(key, 1)
        },
        onGridReady (params) {
            this.gridApi = params.api
            this.gridApi.setSortModel([{
                colId: 'legacyAttributeName',
                sort: 'asc',
            }])

            this.addLegacyAttributeDialogFetching = true

            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.LIST,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    if (data.items.length === 0) {
                        params.api.hideOverlay()
                        params.api.showNoRowsOverlay()
                    }
                    if (this.legacyAttributeNames === null) {
                        this.legacyAttributeNames = data.items.flatMap(({ legacyAttributeName }) => legacyAttributeName)
                        this.addLegacyAttributeDialogFetching = false
                    }
                    successCallback(data.items, data.items[0].totalRows)
                },
            })
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            params.api.setServerSideDatasource(this.serverSideDatasource)
        },
        onModelUpdated () {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit()
            }
        },

        openAddAttributeDialog () {
            this.$v.$reset()
            this.addLegacyAttributeDialogShow = true
            this.addLegacyAttributeDialogParams = {
                attributes: [{
                    iterationKey: new Date().getTime(),
                }],
                legacyAttributeName: '',
                legacyAttributeOption: '',
                productFamily: '',
                removedAttributes: [],
            }
        },
        openEditAttributeDialog (params) {
            if (!this.isAllowedTo('getLegacyAttributeByNameOptionAndProductGroup')) {
                this.$root.$emit('notification:global', {
                    message: 'Not allowed to get legacy attribute by name option and product group',
                    type: 'error',
                })
                return
            }
            this.$v.$reset()
            this.addLegacyAttributeDialogShow = true
            this.addLegacyAttributeDialogFetching = true
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.GET_BY_NAME_OPTION_PRODUCTFAMILY
            this.$api.post(url, {
                ...params,
            }).then(({ data }) => {
                const iterationKey = new Date().getTime()
                this.addLegacyAttributeDialogFetching = false
                this.addLegacyAttributeDialogParams = {
                    ...data.data,
                    attributes: data.data.attributes.map((attribute, key) => {
                        attribute.iterationKey = iterationKey + key

                        return attribute
                    }),
                    removedAttributes: [],
                }
            })
        },
        submitAddAttribute () {
            const params = this.addLegacyAttributeDialogParams
            let url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.ADD_OPTION
            if (params.legacyAttributeId) {
                if (!this.isAllowedTo('updateLegayAttribute')) {
                    this.$root.$emit('notification:global', {
                        message: 'Not allowed to update legacy product',
                        type: 'error',
                    })
                    return
                }
                url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.LEGACY_ATTRIBUTES.UPDATE_OPTION.replace('{legacyAttributeId}', params.legacyAttributeId)
            } else if (!this.isAllowedTo('createLegacyAttribute')) {
                this.$root.$emit('notification:global', {
                    message: 'Not allowed to create legacy product',
                    type: 'error',
                })
                return
            }

            this.addLegacyAttributeDialogLoading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                this.addLegacyAttributeDialogShow = false
                this.$root.$emit('notification:global', {
                    message: 'Legacy attribute was added',
                })

                this.gridApi.purgeServerSideCache()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Adding legacy attribute failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.addLegacyAttributeDialogLoading = false
            })
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST
        if (!this.isAllowedTo('getAttributes')) {
            this.$root.$emit('notification:global', {
                message: `Not allowed to get attributes`,
                type: 'error',
            })
            return
        }
        this.addJob(url)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.attributes = data.data.items.flatMap(attribute => attribute.attributeName)
        }).finally(() => {
            this.finishJob(url)
        })

        if (!this.isAllowedTo('getLegacyProductGroups')) {
            this.$root.$emit('notification:global', {
                message: `Not allowed to get legacy product groups`,
                type: 'error',
            })
            return
        }

        const legacyProductsUrl = CONFIG.API.ROUTES.PRODUCTS.LEGACYPRODUCTS.GET_NAMES
        this.addJob(legacyProductsUrl)
        this.$api.post(legacyProductsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.legacyProducts = data.data.items.map(item => item.productGroup).sort()
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting legacy product names failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(legacyProductsUrl)
        })
    },
}
</script>

<style lang="scss" scoped>
    .v-select-option-add-text {
        display: block;
        margin-top: -1.1em;
    }
</style>
